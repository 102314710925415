<template>
  <PageSection
    inverted
    full
  >
    <section-header>Join us!</section-header>
    <text-narrow>
      Ready to take your fitness to the next level? Sign up today to experience the benefits of outdoor fitness.
    </text-narrow>
    <button
      type="button"
      class="rounded-sm text-white bg-black px-5 py-3 w-64 text-lg font-semibold uppercase hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
      @click="$openTrialModal()"
    >
      Book A Free Trial
    </button>
  </PageSection>
</template>

<script setup lang="ts">
  const { $openTrialModal } = useNuxtApp()
</script>
